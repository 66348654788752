export enum BlogCategoryEnum {
  'Agriculture' = 0,
  'Asset Monitoring' = 1,
  'Business App' = 2,
  'CRM' = 3,
  'Construction' = 4,
  'Digital Transformation' = 5,
  'Digital payments' = 6,
  'EMR/EHR' = 7,
  'Education' = 8,
  'Energy & Utilities' = 9,
  'Finance' = 10,
  'Healthcare' = 11,
  'LIMS' = 12,
  'Logistics' = 13,
  'MVP' = 14,
  'Media & Entertainment' = 15,
  'Mobile' = 16,
  'Outsourcing' = 17,
  'Platform' = 18,
  'Product' = 19,
  'QA' = 20,
  'SaaS Development' = 21,
  'Startup Services' = 22,
  'Technology' = 23,
  'Telemedicine' = 24,
  'Travel & Hospitality' = 25,
  'UI/UX Design' = 26,
  'Web' = 27,
  'HIS' = 28,
  'Team Extension' = 29,
  'SaMD' = 30,
  'Warehouse management' = 31,
  'Freight Forwarding' = 32,
  'ERP' = 33,
}
